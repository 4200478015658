<template>
	<div class="row">
		<h2>{{ $t('invoice.supplier_add') }}</h2>
		
        <div class="col-12">
			<vueDropzone 
				v-if="dropzone_loaded"
				ref="dropzone" 
				id="dropzone" 
				:options="dropzoneOptions"
				@vdropzone-success-multiple="onSuccess"
				@vdropzone-error="onError"
				@vdropzone-file-added="setButton"
			/>
		</div>

        <div v-if="show_button" class="col-12 mt-3 text-center">
            <button @click="save" class="btn btn-primary">{{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /></button>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Constants from 'Constants'
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'
	import Invoice from "@/mixins/Invoice.js"
	import Config from "@/mixins/Config.js"
	import Shutter from "@/mixins/Shutter.js"

	export default {
		name: 'Add',
		mixins: [Invoice, Config, Shutter],
		props: ['tiers_id'],
		data () {
			return {
				processing: false,
				dropzone_loaded: false,
				dropzoneOptions: {
					thumbnailWidth: 250,
					acceptedFiles: 'image/*,application/pdf',
					autoProcessQueue: false,
					uploadMultiple: true,
					dictDefaultMessage: this.getTrad('invoice.upload_invoice_supplier'),
					headers: {
						"Authorization" : "Bearer " + this.getConfig('token'),
						withCredentials: true
					}
		      	},
		      	show_button: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.dropzoneOptions.url = this.getUrlInvoiceSupplierTiers(this.tiers_id)
				if(this.tiers_id) {
					this.dropzoneOptions.url = this.getUrlInvoiceSupplierTiers(this.tiers_id)
				}
				else {
					this.dropzoneOptions.url = Constants.INVOICES_SUPPLIERS_OCR_URL + "?licence_key="+Constants.USER_LICENCE_KEY
				}
				this.dropzone_loaded = true
			},

			async save() {
				if(this.processing) return false

				this.processing = true
				this.$refs.dropzone.processQueue()
			},

			onSuccess() {
				this.processing = false
				this.successToast('toast.info_save_succes')
				this.ok()
				this.shutterPanel().close('invoice-supplier-add')
			},

			onError() {
				this.processing = false
				this.failureToast("toast.info_save_failed")
			},

			setButton() {
				this.show_button = true
			}
		},

		components: {
			vueDropzone: vue2Dropzone
		}
	}

</script>